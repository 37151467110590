import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import store from '@/store'

// NOTE: paths must be explicitly defined for Vite to resolve them correctly (can't use loop)
const LOCALES_PATHS = {
    'en-BE': new URL('@/locales/en-BE.json', import.meta.url).pathname,
    'fr-BE': new URL('@/locales/fr-BE.json', import.meta.url).pathname,
    'nl-BE': new URL('@/locales/nl-BE.json', import.meta.url).pathname,
    technical: new URL('@/locales/technical.json', import.meta.url).pathname,
}

export const SUPPORTED_LOCALES = Object.keys(LOCALES_PATHS)

export const i18n = createI18n({
    locale: 'en-BE',
    fallbackLocale: 'en-BE',
    globalInjection: true,
    silentTranslationWarn: import.meta.env.MODE === 'development',
    warnHtmlMessage: false,
    legacy: false,
    messages,
})

const originalT = i18n.global.t.bind(i18n.global)

// Override the 't' method to allow for locale overwriting
i18n.global.t = function (key, ...args) {
    const prefix = store.getters.getConfig.LOCALE_OVERWRITE
    if (prefix) {
        const newKey = `${prefix}.${key}`
        try {
            if (i18n.global.te(newKey)) return originalT(newKey, ...args)
        } catch (e) {
            // TODO: figure out why *some* $te calls throw an error:
            // eg ing.valuation.start.dashboard
            console.debug('i18n error:', newKey)
        }
    }

    try {
        return originalT(key, ...args)
    } catch (e) {
        console.warn(`Translation key not found: ${key}`)
        return key
    }
}

console.debug(`🌍 i18n loaded with built-in locales: ${i18n.global.availableLocales}`)

export async function setLocale(locale) {
    if (i18n.global.locale.value === locale) return

    // Load locale if not available yet.
    if (!i18n.global.availableLocales.includes(locale)) {
        const messages = await loadLocale(locale)

        if (messages === undefined) return // fetch() error occurred

        // Add locale messages:
        i18n.global.setLocaleMessage(locale, messages)
    }

    i18n.global.locale.value = locale

    // TODO: do we care to set `lang` attribute on html tag? do we need to extract 2-letter language code?
    document.querySelector('html').setAttribute('lang', locale.split('-')[0])
}

export function loadLocale(locale) {
    console.debug(`🌍 Loading locale: ${locale}`)
    return fetch(LOCALES_PATHS[locale])
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            throw new Error('Something went wrong!')
        })
        .catch((error) => {
            console.error('Error fetching locale file:', error)
        })
}
