<template>
    <q-icon v-bind="$attrs" :class="{ 'self-center': selfCenter }">
        <q-badge v-if="indicator" floating color="red" rounded />
        <component :is="IconSrc" v-if="IconSrc" />
    </q-icon>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'

const props = defineProps({
    svgName: {
        type: String,
        required: false,
        default: undefined,
    },
    indicator: {
        type: Boolean,
        required: false,
        default: false,
    },
    selfCenter: {
        type: Boolean,
        required: false,
        default: true,
    },
})

const IconSrc = props.svgName ? defineAsyncComponent(() => import(`./icons/${props.svgName}.vue`)) : undefined
</script>
<style land="css" scoped>
.q-badge {
    /* https://github.com/quasarframework/quasar/issues/17342 */
    min-height: 0px;
    padding: 6px;
}
</style>
